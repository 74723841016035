@import './App-css-variable.scss';

.loading-wrapper {
  display: flex;
  width: 100%;
  height: 100vh;
  position: fixed;
  align-items: center;
  justify-content: center;
}

.MuiSkeleton-text {
  transform: none !important;
}

.MuiSkeleton-text {
  margin: 5px 0px !important;
}

.t-clamp-max-1 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.t-clamp-max-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.t-clamp-max-3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

* {
  outline: none !important;
}

.link {
  color: #0a66c2;
}

a {
  text-decoration: none;
}

[placeholder] {
  text-overflow: ellipsis;
}

header {
  .MuiTabs-indicator {
    height: 4px;
  }
}

ul .MuiListItem-button.with-icon,
ul .MuiListItem-root.with-icon {
  .MuiListItemIcon-root {
    min-width: 35px;
    svg {
      font-size: 23px;
      // color: $app_color_8;
    }
  }

  .MuiTypography-body1,
  p {
    font-size: 14px;
  }
}

.app-view--wrapper.dark {
  header {
    color: #fff;
    background-color: #1b1b1b !important;
  }
  .react-select-style {
    &__control {
      background-color: #666 !important;
    }

    &__single-value {
      color: #fff !important;
      font-size: 14px;
    }
    &__single-value--is-disabled {
      color: #888 !important;
    }
    &__control__single-value {
      color: #fff !important;
    }
    &__control--is-disabled {
      border-color: #222;
      background-color: #333 !important;
    }
    &__option {
      background-color: #fff !important;
      color: #444;
    }
    &__option:hover {
      background-color: #666 !important;
      color: #fff;
    }
  }

  input {
    background-color: #666 !important;
  }

  .link {
    color: #5fafff;
  }
}

.app-view--wrapper.light {
  header {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    // color: #fff !important;
    // // background-color: $app_color_4 !important;
    // //background-image: linear-gradient(135deg, rgb(0, 37, 93), rgb(0, 37, 93));
    // button,
    // a,
    // input {
    //   color: #fff !important;
    // }

    // .divider-between-title {
    //   background-color: rgba(148, 148, 148, 0.7);
    // }
  }

  .MuiAvatar-colorDefault {
    color: inherit;
    background-color: #e4e6eb;
  }
}

.amount--wrapper {
  display: inline-flex;
  align-items: flex-start;
  line-height: 0.4 !important;
  .amount-prefix {
    font-size: 70%;
    margin-right: 3px;
  }
  .amount-decimal {
    font-size: 70%;
  }
}

.font-14 {
  .MuiInputBase-input {
    font-size: 14px !important;
  }
}

button {
  white-space: nowrap;
}

.single-text {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

// iframe {
//   pointer-events: none;
// }
